import React from 'react';
import { Charts } from '../../MatchDetail/Charts';

export const Trading = ({
  trading,
  marketprice,
  teams,
  probability,
  scoregrid,
  maxovers,
}) => {
  return (
    <div className='flex md:gap-[20px]'>
      <div className='flex-grow anOver w-[100%] mb-[10px] bgwhite'>
        <div className='mb-[20px]'>
          <div className='font-anton text-[#fff] text-[40px] text-[30px] md:text-[40px] uppercase'>
            TRADING
          </div>
        </div>

        {trading.map((market, index) => {
          const matchingTrading = marketprice.find(
            (t) => t.market_id === market.market_id
          );
          return market?.priority === '1' && matchingTrading.display === 1 ? (
            <div key={market.market_type + market.market_id}>
              <div className='lg:h-[28px] lg:bg-[#d2ff00] font-chakra bold text-[#18062f] text-[10px] lg:text-[14px] flex border-solid border-[#18062f] border-[1px]'>
                <div className='flex-grow bg-[#d2ff00] pt-[7px] pl-[10px] truncate'>
                  {market?.name || null}
                </div>
                {market?.class !== 'Win' ? (
                  <div className='hidden lg:flex'>
                    <div className='w-[69px] md:w-[100px] pt-[7px] pl-[10px] text-center'>
                      Line
                    </div>
                    <div className='w-[69px] md:w-[100px] pt-[7px] pl-[10px] text-center'>
                      Under
                    </div>
                    <div className='w-[69px] md:w-[100px] w-[10px] pt-[7px] pl-[10px] text-center'>
                      Over
                    </div>
                  </div>
                ) : null}
              </div>
              <div
                className={`${
                  market?.class !== 'Win'
                    ? 'flex lg:flex-col flex-row w-full'
                    : ''
                }`}
              >
                {market?.class !== 'Win' ? (
                  <div className='lg:hidden bg-white text-[#18062f] font-chakra text-[10px] lg:text-[14px] w-full'>
                    <div className='w-[69px] lg:w-[100px] pt-[7px] pl-[10px] text-center'>
                      Line
                    </div>
                    <div className='w-[69px] lg:w-[100px] pt-[7px] pl-[10px] text-center'>
                      Under
                    </div>
                    <div className='w-[69px] lg:w-[100px] w-[10px] pt-[7px] pl-[10px] text-center'>
                      Over
                    </div>
                  </div>
                ) : null}
                {market?.class === 'Win' ? (
                  market.selections.map((sel) => {
                    const matchingSelection = matchingTrading?.selections.find(
                      (selection) => selection.name === sel.name
                    );
                    return (
                      <div
                        key={sel.name + sel.id}
                        className='flex font-chakra bg-white text-[10px] lg:text-[14px] text-[#18062f] border-solid border-[#18062f] border-[1px] border-t-0'
                      >
                        <div className='flex-grow py-[10px] pl-[10px] !text-left bold border-solid border-[#c5c2c8] border-r-[1px]'>
                          {sel.name}
                        </div>
                        <div className='lg:w-[100px] py-[4px] pl-[20px]'>
                          <div
                            className={`w-[69px] py-[8px] !text-center ${
                              matchingTrading?.status === 'ACTIVE'
                                ? 'bg-[#9cd2ff]'
                                : 'bg-[#ffff9f]'
                            }`}
                          >
                            {matchingSelection?.recommended_price.toFixed(2)}
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className='flex flex-col lg:flex-row lg:justify-end font-chakra bg-white text-[10px] lg:text-[14px] text-[#18062f] border-solid border-[#18062f] border-[1px] border-t-0'>
                    <div className='lg:w-[100px] py-[4px] pl-[20px] border-solid border-[#c5c2c8] border-r-[1px]'>
                      <div
                        className={`w-[69px] lg:py-[8px] py-[2px] !text-center`}
                      >
                        {parseFloat(matchingTrading?.line).toFixed(1)}
                      </div>
                    </div>
                    <div className='lg:w-[100px] lg:py-[4px] pl-[20px] border-solid border-[#c5c2c8] border-r-[1px]'>
                      <div
                        className={`w-[69px] lg:py-[8px] py-[2px] !text-center ${
                          matchingTrading?.status === 'ACTIVE'
                            ? 'bg-[#f9c2cb]'
                            : 'bg-[#ffff9f]'
                        }`}
                      >
                        {matchingTrading?.selections[1].recommended_price.toFixed(
                          2
                        )}
                      </div>
                    </div>
                    <div className='lg:w-[100px] py-[4px] pl-[20px]'>
                      <div
                        className={`w-[69px] lg:py-[8px] py-[2px] !text-center ${
                          matchingTrading?.status === 'ACTIVE'
                            ? 'bg-[#9cd2ff]'
                            : 'bg-[#ffff9f]'
                        }`}
                      >
                        {matchingTrading?.selections[0].recommended_price.toFixed(
                          2
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className='h-[20px]' />
            </div>
          ) : null;
        })}
      </div>
      <div className='hidden md:inline'>
        <Charts
          teams={teams}
          probability={probability}
          scoregrid={scoregrid}
          maxovers={maxovers}
          title={false}
        />
      </div>
    </div>
  );
};
export default Trading;
